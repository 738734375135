import React from 'react';
import { FiActivity, FiCast, FiMap, FiMoon, FiCompass } from "react-icons/fi";
import { BiExit, BiAnalyse, BiTargetLock } from "react-icons/bi";
import { SiManageiq, SiMoneygram } from "react-icons/si";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';

const ServiceOne = ({ textAlign, serviceStyle }) => {
    const { t } = useTranslation(); // 正确使用 t 函数

    // 现在ServiceList定义在组件内部，可以访问 t 函数
    const ServiceList = [
        {
            icon: <SiMoneygram />,
            title: t("17"),
            description: t("18"),
        },
        {
            icon: <BiAnalyse />,
            title: t('19'), 
            description: t('20'), 
        },
        {
            icon: <BiTargetLock />,
            title: t('21'), 
            description: t('22'), 
        },
     
    ];

    return (
        <div className={`row row--15 service-wrapper`}>
            {ServiceList.map((val, i) => (
                <div className={`col-lg-4 col-md-12 col-sm-12 col-12`} key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600">{val.title}</h4>
                                <p className="description b1 color-gray mb--0">{val.description}</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    );
}

export default ServiceOne;
