import React from 'react';
import { FiPhone, FiMapPin, FiFile } from "react-icons/fi";
import { useTranslation } from 'react-i18next';



const About4 = () => {
    const { t } = useTranslation();
    return (
        <div className="about-style-5 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <div className="thumbnail">
                                        <img src="./images/about/lorenzo1.png" alt="About Images" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="inner">
                                        <p>{t("105")}</p>
                                        <p>{t("106")}</p>
                                        <p>{t("107")}</p>

                                        <p>{t("108")}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="inner">
                                        <p>{t("109")}</p>
                                        <p>{t("110")}</p>
                                        <p>{t("111")}</p>
                                        <p>{t("193")}</p>
                                        <p>{t("194")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About4;