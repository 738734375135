import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import Team from '../elements/team/Team';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from "react-icons/fi";

const AboutTeam = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="About Us - Cyberate Investments, our value, our management scale, our people" />
            <main className="page-wrapper">
                <HeaderOne />
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">{t("500")}</h1>
                                    <p className="description" style={{ fontSize: '20px' }}>{t("510")}</p>
                                    {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-5 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/Team.jpg" alt="Banner Images" style={{ width: '100%', height: '100%', transform: 'scale(1.0)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="service-area">
                    <div className="container">
                        <div className="col">
                            <div className="col-lg-12">
                                <p className="mb--10"> {t("510")} </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Team />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AboutTeam;
