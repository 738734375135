import React from 'react';
import Typed from 'react-typed';
import { useTranslation } from 'react-i18next';
import ComparisonItem from './ComparisonItem';

const About3 = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/架构1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                {/* <h2 className="title">移民的大量涌入 <br /> {" "} */}
                                {/* <Typed className="theme-gradient"
                                        strings={[
                                            "Consulting.",
                                            "Finance.",
                                            "Agency.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    /> */}
                                {/* </h2> */}
                                <p>{t("92")}</p>
                                {/* <p>{t("93")}</p>
                                <p>{t("94")}</p>
                                <p>{t("95")}</p>
                                <p>{t("96")}</p>
                                <p>{t("97")}</p> */}
                                <ComparisonItem id="93" />
                                <ComparisonItem id="94" />
                                <ComparisonItem id="95" />
                                <ComparisonItem id="96" />
                                <ComparisonItem id="97" />

                                {/* <br/>
                                <p>我們將為您量身打造：</p>
                                <p>1. 資產保護策略，以確保您的財產安全。</p>
                                <p>2. 稅務規劃方案，幫助您最大程度地減少稅負。</p>
                                <p>3. 融資和借貸規劃，以最有效的方式獲取資金並管理債務。</p>
                                <p>4. 財務傳承計劃，確保您的財產在傳承過程中順利轉移。</p>
                                <p>5. 房產規劃，包括增值、套現等策略，以最大化房產投資回報。</p>
                                <p>6. 我們跨足多專業領域，包括金融、法律、過戶、驗房、會計等，為您提供全方位的支持和諮詢.</p> */}

                                <div className="read-more-btn mt--40">
                                    {/* <a className="btn-default" href="#"><span>More About Us</span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About3