import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';
import FlowChart from './FlowChart';



const ServiceEleven = ({ textAlign, serviceStyle }) => {
    const { t, i18n } = useTranslation();
    const ServiceList = [
        {
            icon: '1',
            subtitle: t("79"),
            title: t("195"),
            // description: '在深入讨论并共同探索先进的投资策略和市场机遇。'
        },
        {
            icon: '2',
            subtitle: t("196"),
            title: t("80"),
            // description: '帮助您全面理解和分析潜在投资项目的价值和风险，确保您做出明智的投资决策。'
        },
        {
            icon: '3',
            subtitle: t("197"),
            title: t("81"),
            // description: '为客户提供深入、精准的土地价值评估和潜在发展概况，确保他们能够做出最具前瞻性的投资决策。'
        },
        {
            icon: '4',
            subtitle: t("198"),
            title: t("199"),
            // description: '开发审批流程涉及详细的规划评估'
        },
        {
            icon: '5',
            subtitle: t("200"),
            title: t("201"),
            // description: '开发审批流程涉及详细的规划评估'
        },

    ]


    return (
        <div className="row row--15 service-wrapper">
            {/* {ServiceList.map((val, i) => (
                   <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                    <div className={`service ${serviceStyle} ${textAlign}`}>
                        <div className="icon">
                            <div className="line"></div>
                            {val.icon}
                        </div>
                        <div>
                        <div className="content">
                        <h3 className="subtitle" style={{ color: '#ffd700' }}> 
                            <span dangerouslySetInnerHTML={{__html: val.subtitle}}></span>
                        </h3>


                            <h4 className="title">
                                <span dangerouslySetInnerHTML={{__html: val.title}}></span>
                            </h4>
                            <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                        </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        ))} */}
            <FlowChart serviceList={ServiceList} />
        </div>

    )
}
export default ServiceEleven;