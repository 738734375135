import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';

const ServiceThree = ({ textAlign, serviceStyle }) => {
    const { t } = useTranslation(); // 正确使用 t 函数
    const ServiceList = [
        {
            image: '/images/service/图片8.png',
            title: t("505"),
            description: t("506")
        },

        {
            image: './images/service/图片2.png',
            title: t("29"),
            description: t("30")
        },
        {
            image: '/images/service/图片3.png',
            title: t("31"),
            description: t("32")
        },
        {
            image: '/images/service/图片4.png',
            title: t("33"),
            description: t("34")
        },
        {
            image: '/images/service/图片5.png',
            title: t("35"),
            description: t("36")
        },
        // {
        //     image: '/images/service/图片6.png',
        //     title: 'Future planning',
        //     description: 'Study the regional government and planning and development plans to understand the future direction of urban expansion and new development projects.'
        // },
        // {
        //     image: '/images/service/图片7.png',
        //     title: 'Investment Budget',
        //     description: 'Choose the right area according to your available budget and financial situation, some areas are too expensive and not suitable for small to medium sized investors..'
        // },


    ]

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-12 col-sm-12 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to="/marketing" dangerouslySetInnerHTML={{ __html: val.title }}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;