import React from 'react';
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterOne from '../common/footer/FooterOne';


import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutTwo from "../elements/about/AboutTwo";
import SlipThree from "../elements/split/SlipThree";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import About9 from '../elements/about/About9';
import { useTranslation } from 'react-i18next';


var BlogListData = BlogClassicData.slice(0, 3);



const Finance = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <SEO title="Finance" />
            <main className="page-wrapper">
                <HeaderOne></HeaderOne>

                {/* Start Slider area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">{t("124")}<br />{t("125")} {" "}

                                        {t("126")}


                                    </h1>
                                    {/* <p className="description">We help our clients succeed by creating brand identities, digital experiences, and print materials.</p> */}
                                    {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")} <i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/资金保障2.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* End Slider area  */}

                <About9 />







                {/* Start Elements Area  */}

                {/* End Elements Area  */}


                {/* Start Elements Area  */}

                {/* End Elements Area  */}

                <Separator />



                <FooterTwo />
                <Copyright />

            </main>
        </>
    )
}

export default Finance;
