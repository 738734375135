import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import Service1 from '../service/Service1';
import Service2 from '../service/Service2';
import ServiceEleven from '../service/ServiceEleven';
import ServiceFour from '../service/ServiceFour';
import { useTranslation } from 'react-i18next';

const Accordion3 = ({ customStyle }) => {
    const { t, i18n } = useTranslation();
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <h4>{t("78")}</h4>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="rn-service-area ">
                            <div className="container">

                                <Service2
                                    serviceStyle="service__style--1  icon-circle-style with-working-process"
                                    textAlign="text-center"
                                />
                            </div>
                        </div>
                    </Card.Body>

                </Accordion.Collapse>

            </Card>





        </Accordion>



    )
}
export default Accordion3