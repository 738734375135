import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
// import ContactForm  from './ContactForm';
import SectionTitle from "../sectionTitle/SectionTitle";
import { useTranslation } from 'react-i18next';

const ContactOne = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t("143")} </h4><br />
                                        <p><a href="tel:+61 (08) 82579123">+61 (08) 82579123</a></p>
                                
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t("144")} </h4><br />
                                        <p><a href="info@cyberate.com.au">info@cyberate.investments</a></p>
                                  
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t("145")} </h4>
                                        <p>Office 8/9-15 Field Street<br /> Adelaide, Australia, 5000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>  <br/>  <br/>  <br/>

            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "联络表格"
                                        title = {t("146")} 
                                        description = ""
                                    />
                                </div>
            </div>
            <ContactForm currentPageUrl={window.location.href} />
            {/* <div className="parent-container">
                <h2 className="title">请留下您的联系方式</h2>
            </div> */}

            {/* <ContactForm/> */}
       
            {/* <div className="row mt--40 row--15">        
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <div className="container"> */}
           <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="inner">
                                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                <GoogleMapStyle style={{ width: '70%', height: '70%' }}/>
                            </div>
                        </div>
                     </div>
                    </div>
                </div>
            </div>


                

        </>
    )
}
export default ContactOne;