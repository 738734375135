import React from 'react';

const BrandList = [
    {
        image: './images/brand/newlogo-06.png',
        url: 'https://www.corelogic.com.au/'
    },
    {
        image: './images/brand/cyberate.png',
        url:'https://www.cyberate.com.au/'
    },
  
    {
        image: './images/brand/newlogo-11.png',
        url:'https://www.quaintquant.com/'
    },

   
  
]

const BrandThree = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index} style={{ margin: '20px' }}>
                    <a href={data.url} target='_blank'> <img src={`${data.image}`} alt="Brand Image" /> </a>
                </li>

                
            ))}
        </ul>
    )
}

export default BrandThree;
