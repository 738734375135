import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const AboutSeven = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    {/* <h4 className="subtitle"><span className="theme-gradient">Corporate About.</span></h4> */}
                                    <h2 className="title mt--10">{t("50")}</h2>
                                    
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>{t("51")}</p>

                                    {/* <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> 住房供需失衡：住房供需失衡意味着需求超过供应。这种情况常常导致房价上涨，因为购房者竞争有限的房源。对于投资者来说，这意味着他们的地产资产价值有可能增加，从而带来潜在的高回报。</li>
                                        <li><span className="icon"><FiCheck /></span> 移民的大量涌入：移民通常需要住房，无论是购买还是租赁，这增加了对房地产的需求，特别是在那些移民集中的地区。政府的住房政策，如住房补贴和帮买政策，旨在帮助人们购买房屋。这些政策可以刺激房地产市场，增加对房产的购买力，从而对投资者有利。</li>
                                        <li><span className="icon"><FiCheck /></span> 政府的相关政策：如今南澳政府出台多项政提供减税或税收抵免等措施。包括降低土地税，放置低息贷款为投资者提供一个更加有利的平台。</li>
                
                                    </ul> */}
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/AboutUs">{t("52")} <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/ai.jpg" alt="Cyberate Investments" />
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutSeven;