import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import BlogList from "../../components/blog/itemProp/BlogList";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import BlogClassicData from '../../data/blog/BlogList.json';


var BlogListData = BlogClassicData.slice(0, 3);


const ServiceList = [
  {
    image: '/images/service/galery-01.png',
    title: 'Real Estate',
    description: 'We quantitatively analyse the market and plan indicators.'
  },
  {
    image: '/images/service/galery-02.png',
    title: 'Stocks',
    description: 'We automatically and quatitatively trade in the market.'
  },
  // {
  //     image: '/images/service/galery-03.png',
  //     title: 'Cryptocurrencies',
  //     description: 'Variations There are many of pass.'
  // },
  {
    image: '/images/service/galery-04.png',
    title: 'Innovative Projects',
    description: 'We invest in and internally incubate innovative projects.'
  }
];
// const ServiceFive = ({ textAlign, serviceStyle }) => {
//     return (

//     <div className="row row--15 service-wrapper">
//       {ServiceList.map((val, i) => (
//         <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
//           <ScrollAnimation
//             animateIn="fadeInUp"
//             animateOut="fadeInOut"
//             animateOnce={true}
//           >
//             <div className={`service ${serviceStyle} ${textAlign}`}>
//               <div className="inner">
//                 <div className="content text-center">
//                   <div className="content">
//                     <h4 className="title">
//                       <Link to="/marketing" dangerouslySetInnerHTML={{ __html: val.title }}></Link>
//                     </h4>
//                     <p className="description" dangerouslySetInnerHTML={{ __html: val.description }}></p>
//                   </div>
//                   <div className="image">
//                     <img src={`${val.image}`} alt="card Images" style={{ width: '80%', height: '80%' }} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </ScrollAnimation>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ServiceFive;

const ServiceFive = ({ textAlign, serviceStyle }) => {
    return (
        <div className="blog-area rn-section-gap">
        <div className="container">
            {/* <div className="row">
                <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Latests News"
                        title = "Our Latest News."
                        description = "We provide company and finance service for <br /> startups and company business."
                    />
                </div>
            </div> */}
            <div className="row row--15">
                {BlogListData.map((item) => (
                    <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                        <BlogList StyleVar="box-card-style-default" data={item} />
                    </div>
                ))}
            </div>
        </div>
        </div>
            
 );
};
         
export default ServiceFive;

