import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import './AboutUs.css'



const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="About Us - Cyberate Investments, our value, our management scale, our people" />
            <main className="page-wrapper">
                <HeaderOne></HeaderOne>
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">{t("38")}</h1>
                                    <p className="description">{t("507")}</p>
                                    {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/服务2.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
                {/* Start Service Area  */}
                <div className="service-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">{t("40")}</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10"> {t("41")} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="col">
                            <div className="col-lg-12">
                                <div className="content">
                                    <h3 className="title">{t("508")}</h3>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <p className="mb--10"> {t("509")} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stats">
                    <div className="stat-item">
                        <div className="number">
                            80<span className="plus">+</span>
                        </div>
                        <div className="labels">
                            <span>{t("518")}</span>
                            <span>{t("519")}</span>
                        </div>
                    </div>
                    <div className="stat-item">
                        <div className="number">
                            40<span className="plus">+</span>
                        </div>
                        <div className="labels">
                            <span>{t("520")}</span>
                            <span>{t("521")}</span>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/Team.jpg" alt="Cyberate Investment Team" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title"></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default AboutUs;
