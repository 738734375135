import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Logo from "../logo/Logo";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { useTranslation } from 'react-i18next';


const CalltoActionSeven = () => {
    const { t } = useTranslation();
    const callToActionData = {
        title: "Ready to start to invest with us?",
        subtitle: t("37"),
        btnText: "Start",
    }


    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-7 col-md-7">
                        <div>
                            <div className="content text-start">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/logonew.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logonew.png`}
                                />
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="subtitle">{callToActionData.subtitle}</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                        <div className="rn-footer-widget">
                            <p className="title" style={{ fontSize: 30 }}>{t("53")}</p>
                            <div className="inner">
                                <p>Address: Office 8/9-15 Field Street, Adelaide, SA 5000</p>
                                <p>Email: info@cyberate.investments</p>
                                <p>+61 (08) 82579123</p>
                                {/* <ul className="social-icon social-default justify-content-start">
                                    <li><Link to="facebook.com"><FiFacebook /></Link></li>
                                    <li><Link to="twitter.com"><FiTwitter /></Link></li>
                                    <li><Link to="instagram.com"><FiInstagram /></Link></li>
                                    <li><Link to="linkdin.com"><FiLinkedin /></Link></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-4">
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="call-to-btn text-start mt_sm--20 text-md-end">
                                <a className="btn-default btn-icon" target="_blank" href="#">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                            </div>
                        </ScrollAnimation>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSeven;