import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";
import { FiX , FiArrowRight} from "react-icons/fi";

const Data = [
    {
        countNum : 199,
        countTitle: 'Happy Clients.',
    },
    {
        countNum : 575,
        countTitle: 'Employees',
    },
    {
        countNum : 69,
        countTitle: 'Useful Programs',
    },
    {
        countNum : 500,
        countTitle: 'Useful Programs',
    },
];

const SlipThree = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/about/management.jpg" alt="Development Project Management" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">我们的服務對象</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">大众投資者： 选择我们，如果您手头有一些闲置资金并希望通过房地产投资实现收益，我们建议您制定了一些小规模、低风险的项目，如购买出租物业或投资于潜力区域的小型开发项目，这样既能积累经验，又能在控制风险的前提下逐步实现财富增长。</p>
                            </ScrollAnimation>


                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">企業投資者：选择我们，如果您的公司在寻求多元化投资组合和可靠的资产增值机会时，房地产投资提供了一个充满潜力的领域，找到我们，可以通过精准市场分析和战略布局，可为企业带来长期稳定的收益。</p>
                            </ScrollAnimation>

                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">房地產企業：选择我们，您将获得专业的市场分析、精确的资产评估和全面的投资策略规划，我们将助您在房地产市场中稳步增长资产，实现您的投资目标。</p>
                            </ScrollAnimation>

                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">政府單位：选择我们。我们能提供基于深入市场分析的项目规划、有效的资产管理方案，以及与政府发展目标相协调的可持续发展策略，共同促进地区经济增长和社区繁荣。</p>
                            </ScrollAnimation>


                            {/* Add a button to jump into development page */}
                            <div className="button-group mt--30">
                                <a className="btn-default btn-medium btn-icon" href="\contact">联系我们<i className="icon"><FiArrowRight /></i></a>
                            </div>

                            {/* <div className="row">
                                {Data.map((data, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="count-box counter-style-4 text-start">
                                            <TrackVisibility once>
                                                {({ isVisible }) => isVisible && 
                                                    <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                                            </TrackVisibility>
                                            <h5 className="counter-title">{data.countTitle}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipThree;