import React from 'react';

const BrandList = [
    {
        image: './images/brand/logo-13.png'
    },
    {
        image: './images/brand/rics.png'
    },
    {
        image: './images/brand/newlogo-08.png'
    },
    
    {
        image: './images/brand/ANAhomes.png',
        url:'https://ana.homes/'
    },
    {
        image: './images/brand/yougou.png',
        url:'https://www.yogodesign.net/'
    },
    {
        image: './images/brand/xinke.png',
        url:'https://nusunm.com/'
    },
    {
        image: './images/brand/design.png',
       
    },
  

   
  
]

const BrandSix = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index} style={{ margin: '20px' }}>
                   <a href={data.url} target='_blank'> <img src={`${data.image}`} alt="Brand Image" /> </a>
                </li>
            ))}
        </ul>
    )
}

export default BrandSix;
