import React from 'react'

import About8 from '../about/About8';
import { useTranslation } from 'react-i18next';
import './about.css'

const Accordion6 = ({ customStyle }) => {
        const { t, i18n } = useTranslation();

        <style>
                {`
      .custom-card-container .card-body {
        padding: 100px !important;
        border: 1px solid black !important;
      }
    `}
        </style>
        return (

                <div className="custom-card-container">

                        <div className="rn-service-area rn-section-gap noBottom">
                                <div className="container">

                                        <About8
                                                serviceStyle="service__style--1  icon-circle-style with-working-process"
                                                textAlign="text-center"
                                        />
                                </div>
                        </div>



                </div>





        )
}
export default Accordion6