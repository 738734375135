import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
// import ContactForm  from './ContactForm';
import SectionTitle from "../sectionTitle/SectionTitle";
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
        

            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "联络表格"
                                        title = {t("146")} 
                                        description = ""
                                    />
                                </div>
            </div>
            <ContactForm currentPageUrl={window.location.href}/>
    
                

        </>
    )
}
export default ContactUs;