import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import { useTranslation } from "react-i18next";


const GoogleMapStyle = ({
  containerClassName = "google-map",
  mapClassName = "map",
  mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.0283607163124!2d138.59323477543043!3d-34.930825674846545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf2119aed3b5%3A0x3760f8447dcf31ce!2s9%2F15%20Field%20St%2C%20Adelaide%20SA%205000!5e0!3m2!1szh-CN!2sau!4v1708998679657!5m2!1szh-CN!2sau",
}) => {
  const [mapsrc, setMapSrc] = useState(mapSrc)
  const { i18n } = useTranslation();
  console.log(i18n, 'insadasdasd');

  useEffect(() => {
    const lang = {
      "en": "en",
      "zh": "zh-CN"
    }
    const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.0283607163124!2d138.59323477543043!3d-34.930825674846545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf2119aed3b5%3A0x3760f8447dcf31ce!2s9%2F15%20Field%20St%2C%20Adelaide%20SA%205000!5e0!3m2!1s${lang[i18n.language]}!2sau!4v1708998679657!5m2!1s${lang[i18n.language]}!2sau`;
    setMapSrc(mapSrc)
  }, [i18n.language])
  const mapStyle = {
    position: "relative",
    display: "block",
    top: "1px",
    zoom: 3.75,
    width: '100%',
    paddingTop: 20,
    paddingRight: 3,
    paddingLeft: 3,
  };

  return (
    <div className={containerClassName}>
      <section style={mapStyle} className={mapClassName}>
        <iframe src={mapsrc} allowFullScreen style={{ width: "100%", borderRadius: 2 }}></iframe>
      </section>
    </div>
  );
};

export default GoogleMapStyle;













