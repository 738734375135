import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceSix from "./ServiceSix";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceSeven from "./ServiceSeven";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import ServiceOne from './ServiceOne';
import Copyright from '../../common/footer/Copyright';
import FooterTwo from '../../common/footer/FooterTwo';
import AnotherServiceOne from './AnotherServiceOne';
import AnotherServiceTwo from './AnotherServiceTwo';
import About1 from '../about/About1';
import AccordionThree from '../accordion/AccordionThree';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionFour from '../accordion/AccordionFour';
import About3 from '../about/About3';
import AccordionFive from '../accordion/AccordionFive';
import Accordion1 from '../accordion/Accordion1';
import Accordion3 from '../accordion/Accordion3';
import About5 from '../about/About5';
import { useTranslation } from 'react-i18next';
import ContactUs from '../contact/ContactUs';
import About20 from '../about/About20';


const FirstHomeBuyers = () => {
    const { t, i18n } = useTranslation();
    return (
        <>

            {/* <SEO title="Service || Cyberate Investments Pty Ltd" />
            <Layout> */}
            {/* <HeaderTopBar></HeaderTopBar> */}
            <HeaderOne></HeaderOne>
            <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div className="inner text-start">
                                <h1 className="title theme-gradient display-two"> {" "}

                                    {t("192")}


                                </h1>
                                {/* <p className="description">We help our clients succeed by creating brand identities, digital experiences, and print materials.</p> */}
                                <div className="button-group">
                                    {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link> */}
                                    {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")}<i className="icon"><FiArrowRight /></i></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="thumbnail">
                                <img src="./images/bg/个人投资2.png" alt="First home buyer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-content">

                {/* Start Service Area  */}

                <div className="rn-service-area ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t("150")}
                                    title={t("192")}
                                // description="When selecting a location for investment,  <br /> we typically consider the following key factors in developing our project selection strategy."
                                />
                            </div>
                        </div>
                        <About20
                            serviceStyle="service__style--2"
                            textAlign="text-center"
                        />

                    </div>





                </div>

                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        // subtitle = {t("141")} 
                                        // title = {t("142")} 
                                        description=""
                                    />
                                </div>
                            </div>

                            <ContactUs currentPageUrl={window.location.href} />



                        </div>
                    </div>

                </div>





                <Separator />


                <Separator />








            </div>

            {/* </Layout> */}
            <FooterTwo />
            <Copyright />

        </>
    )
}

export default FirstHomeBuyers;