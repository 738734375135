// import { FlowGraphExtension } from '@antv/xflow-extension';
import React from 'react';

const BrandList = [
  {
    image: './images/brand/newlogo-01.png',
    url: 'https://www.ljhooker.com.au/'
  },

  {
    image: './images/brand/logo-10.png',
    url: 'https://www.firstnational.com.au/'
  },
  {
    image: './images/brand/raywhite.png',
    url: 'https://www.raywhite.com/'
  },
  {
    image: './images/brand/newlogo-03.png',
    url: 'https://www.kaidere.com.au/'
  },
  {
    image: './images/brand/newlogo-04.png',
    url: 'https://acrealestate.com.au/'
  },
  {
    image: './images/brand/sinova.png',
    url: 'https://www.sinova.com.au/'
  },
  {
    image: './images/brand/Harcourts.png',
    url: 'https://harcourts.net/au'
  },
  // {
  //     image: './images/brand/newlogo-05.png'
  // },

]

const layout = {
  // display: flex,
  gap: '100px',
  margin: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
};



const BrandTwo = ({ brandStyle }) => {
  return (
    <div className="layout">
      <ul className={`brand-list ${brandStyle}`}>
        {BrandList.map((data, index) => (
          <li key={index} style={{ margin: '20px' }}>
            <a href={data.url} target='_blank' rel="noreferrer" style={{ padding: "10px", background: "white" }}>
              <img src={`${data.image}`} alt="" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};


export default BrandTwo;
