import React from 'react';
import './FlowChart.css';
import ScrollAnimation from 'react-animate-on-scroll';

const colors = [
  '#4A5C6F',  // --color-primary-darker2
  '#7C4D8B',  // --color-tertiary-darker2
  '#4D2C2C',  // --color-secondary-darker2
  '#003C4A',  // --color-light-green-darker2
  '#6C2B48',  // --color-pink-darker2
];

const FlowChart = ({ serviceList }) => {
  // 根据 serviceList 长度计算圆圈大小
  const getCircleSize = () => {
    const baseSize = 60; // 基础大小
    const minSize = 40; // 最小大小
    const maxSize = 100; // 最大大小
    const size = baseSize + (7 - serviceList.length) * 10; // 每少一项，增加10px
    return Math.min(Math.max(size, minSize), maxSize); // 限制在最小和最大值之间
  };

  const circleSize = getCircleSize();

  return (
    <div className="flow-chart-container">
      <div className="flow-chart-wrapper">
        {serviceList.map((service, index) => (
          <ScrollAnimation
            key={index}
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
            className="flow-item"
          >
            <div
              className="icon-circle"
              style={{
                backgroundColor: colors[index % colors.length],
                width: `${circleSize}px`,
                height: `${circleSize}px`,
                fontSize: `${circleSize / 2}px` // 调整图标大小
              }}
            >
              <span>{service.icon}</span>
            </div>
            <h4 className='subtitle'>{service?.subtitle}</h4>
            <h5 dangerouslySetInnerHTML={{ __html: service.title }}></h5>
            {index < serviceList.length - 1 && <div className="connector"></div>}
          </ScrollAnimation>
        ))}
      </div>
    </div>
  );
};

export default FlowChart;