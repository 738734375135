import React from 'react';
import Typed from 'react-typed';
import { useTranslation } from 'react-i18next';
import ComparisonItem from './ComparisonItem';

const About1 = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/个人1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                {/* <h2 className="title">移民的大量涌入 <br /> {" "} */}
                                {/* <Typed className="theme-gradient"
                                        strings={[
                                            "Consulting.",
                                            "Finance.",
                                            "Agency.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    /> */}
                                {/* </h2> */}
                                <p>{t("72")}</p>
                                <p>{t("73")}</p>
                                {/* <p>{t("74")}</p>
                                <p>{t("75")}</p>
                                <p>{t("76")}</p>
                                <p>{t("77")}</p> */}
                                <ComparisonItem id="74" />
                                <ComparisonItem id="75" />
                                <ComparisonItem id="76" />
                                <ComparisonItem id="77" />

                                <div className="read-more-btn mt--40">
                                    {/* <a className="btn-default" href="#"><span>More About Us</span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About1