import React from 'react';

const BrandList = [
    {
        image: './images/brand/logo-09.png',
        url: 'https://lynchmeyer.com.au/'
    },
    {
        image: './images/brand/newlogo-07.png',
        url: 'https://yapplawyers.com.au/'
    },
    {
        image: './images/brand/韬安.png',
        url: 'http://www.taoanlaw.com/'
    },
    {
        image: './images/brand/通茂.png',
    },





]

const BrandFour = ({ brandStyle }) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>

            {BrandList.map((data, index) => (
                <li key={index} style={{ margin: '20px' }}>
                    <a href={data.url} target='_blank' rel="noreferrer"> <img src={`${data.image}`} alt="Brand Image" /> </a>
                </li>
            ))}

            <br />
        </ul>
    )
}

export default BrandFour;
