import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BrandThree from '../elements/brand/BrandThree';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import BrandTwo from "../elements/brand/BrandTwo";
import BrandFour from '../elements/brand/BrandFour';
import BrandFive from '../elements/brand/BrandFive';
import BrandSix from '../elements/brand/BrandSix';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const AboutPartner = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="About Us - Cyberate Investments, our value, our management scale, our people" />
            <main className="page-wrapper">

                <HeaderOne></HeaderOne>
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">{t("502")}</h1>

                                    <p className="description" style={{ fontSize: '20px' }}>{t("187")}</p>
                                    {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/服务2.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("182")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <BrandTwo brandStyle="brand-style-2" style={{ paddingLeft: '20px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("183")}</h3>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                                    <BrandThree brandStyle="brand-style-2" />

                                    {/* <MyLineChart  style={{paddingRight:20, width:"100%", alignContent:"center"}} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("184")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                                    <BrandFour brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("185")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                                    <BrandFive brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("186")}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                                    <BrandSix brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AboutPartner;
