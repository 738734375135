import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceSix from "./ServiceSix";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import ServiceOne from './ServiceOne';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';
import ServiceEight from './ServiceEight';
import ServiceNine from './ServiceNine';
import ServiceTen from './ServiceTen';
import SlipThree from '../split/SlipThree';
import AboutTwo from '../about/AboutTwo';
import AccordionOne from '../accordion/AccordionOne';
import AccordionThree from '../accordion/AccordionThree';
import BlogPropTwo from '../../components/blog/itemProp/BlogPropTwo';
import About1 from '../about/About1';
import About2 from '../about/About2';
import AccordionFour from '../accordion/AccordionFour';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import About3 from '../about/About3';
import AccordionFive from '../accordion/AccordionFive';
import Accordion1 from '../accordion/Accordion1';
import About5 from '../about/About5';
import Accordion2 from '../accordion/Accordion2';
import { useTranslation } from 'react-i18next';
import ContactForm from '../contact/ContactForm';
import ContactOne from '../contact/ContactOne';
import ContactUs from '../contact/ContactUs';
import { Tab } from 'react-tabs';


const PersonalRealInvestment = () => {
    const { t, i18n } = useTranslation();
    return (
        <>

            {/* <SEO title="Service || Cyberate Investments Pty Ltd" />
            <Layout> */}
            {/* <HeaderTopBar></HeaderTopBar> */}
            <HeaderOne></HeaderOne>

            {/* <BreadcrumbOne

                title="服务项目 <br />"
                rootUrl="/"
                parentUrl="首页"
                currentUrl="服务"
            /> */}
            <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div className="inner text-start">
                                <h1 className="title theme-gradient display-two"> {" "}

                                    {t("69")}


                                </h1>
                                {/* <p className="description">We help our clients succeed by creating brand identities, digital experiences, and print materials.</p> */}
                                {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#"> {t("39")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="thumbnail">
                                <img src="./images/bg/个人建房1.png" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-content">

                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                        
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "Come and see us with any questions <br /> you may have about investing in your Real Estate ."
                                     />
                                </div>
                                
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-center"
                            />
                        </div>
                    </div> */}
                {/* End Service Area  */}


                {/* <Separator /> */}


                <Separator />





                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        title = "What we can do for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div> */}
                {/* End Service Area  */}

                {/* <Separator /> */}

                {/* Start Service Area  */}
                <div className="rn-service-area ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    title={t("71")}
                                    subtitle={t("70")}

                                // description="When selecting a location for investment,  <br /> we typically consider the following key factors in developing our project selection strategy."
                                />
                            </div>
                        </div>
                        <About1
                            serviceStyle="service__style--2"
                            textAlign="text-center"
                        />

                    </div>

                    <div className="rn-about-area about-style-3 ">
                        <div className="container">
                            <div className="row row--30">
                                <div className="col-lg-5">

                                </div>
                                <div className="col-lg-12 mt_md--80 mt_sm--80">
                                    <div className="inner">
                                        <div className="section-title">

                                        </div>
                                        <AccordionThree customStyle="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rn-about-area about-style-3" style={{ marginTop: window.innerWidth < 770 ? "100px" : '240px' }}>
                        {/* <div className="container">
                            <div className="row row--30">
                                <div className="col-lg-5">

                                </div>
                                <div className="col-lg-12" style={{ marginTop: '40px' }}>
                                    <div className="inner">
                                        <div className="section-title">

                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <AccordionFour customStyle="" />
                    </div>

                </div>




                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        // subtitle = {t("141")} 
                                        // title = {t("142")} 
                                        description=""
                                    />
                                </div>
                            </div>

                            <ContactUs currentPageUrl={window.location.href} />



                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>



                {/* <div className="slider-area slider-style-1 bg-transparent variation-2 height-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12"> 改为 col-lg-12 以使用全宽度
                            <div className="inner d-flex justify-content-end"> 应用 Flexbox
                                <div className="button-group">
                                    <Link className="btn-default btn-medium btn-border round btn-icon" to="/Contact">{t("53")}<i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
















                {/* End Service Area  */}

                {/* <Separator /> */}

                {/* <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Service provide for you."
                                    title="How we do data analysis."
                                    description="We collect, process, analyse and interpret,  <br /> real estate market data to decide whether to invest or not."
                                />
                            </div>
                        </div>
                        <ServiceEight
                            serviceStyle="service__style--2"
                            textAlign="text-center"
                        />
                    </div>
                </div> */}
                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle=" "
                                    title="How we analyse the market."
                                    description="When conducting our market analysis, <br /> we typically take the following steps to assess the property market and develop an investment strategy."
                                />
                            </div>
                        </div>
                        <ServiceNine
                            serviceStyle="service__style--2"
                            textAlign="text-center"
                        />
                    </div>
                </div> */}
                {/* End Service Area  */}


                {/* <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="How about the return on investment."
                                    title="Our return on investment"
                                    description="We assess the profitability of their investments by calculating the rate of return."
                                />
                            </div>
                        </div>
                        <ServiceTen
                            serviceStyle="service__style--2"
                            textAlign="text-center"
                        />
                    </div>
                </div> */}
                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 icon-circle-style"
                                textAlign = "text-center"
                             />
                        </div>
                    </div> */}
                {/* End Service Area  */}
                {/* 
                <div className="rn-service-area rn-section-gap">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div>

                <Separator /> */}



                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceFour 
                                serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                textAlign = "text-center"
                             />
                        </div>
                    </div> */}
                {/* End Service Area  */}


                {/* <Separator /> */}



                {/* <ServiceFive> </ServiceFive> */}

                <FooterTwo />
                <Copyright />
            </div>

            {/* </Layout> */}

        </>
    )
}

export default PersonalRealInvestment;
