import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import AboutTwo from '../elements/about/AboutTwo';
import AboutThree from '../elements/about/AboutThree';
import AboutFour from '../elements/about/AboutFour';
import SlipThree from '../elements/split/SlipThree';
import ServiceFour from '../elements/service/ServiceFour';
import ServiceThree from '../elements/service/ServiceThree';

import About from '../elements/about/About';
import About1 from '../elements/about/About1';
import About2 from '../elements/about/About2';

import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import Company from "./../pages/Company";
import { FiArrowRight, FiCheck } from "react-icons/fi";

import ServiceOne from '../elements/service/ServiceOne';
import ServicenewOne from '../elements/service/ServicenewOne';
import Split from '../elements/split/Split';
import MyLineChart from "../elements/split/chart";

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {

    const { t, i18n } = useTranslation();

    const videoContainerStyle = {
        position: 'relative',
        width: '100%',
        height: '100vh', // 将高度设置为视口高度
        overflow: 'hidden'
    };

    const videoStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover', // 保持视频比例并覆盖容器
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // 30%透明黑色
        zIndex: 0
    };

    const textContainerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        textAlign: 'center'
    };

    return (
        <>
            <SEO title="Cyberate Investment - We help our investors succeed by innovatively analysing the market." />
            <main className="page-wrapper">
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Video Area */}
                <div style={videoContainerStyle}>
                    <video autoPlay loop muted style={videoStyle}>
                        <source src={`${process.env.PUBLIC_URL}/images/about/Cyberate Investment_final.mp4`} type="video/mp4" />
                        您的浏览器不支持视频播放。
                    </video>
                    <div style={overlayStyle}></div>
                    <div className="container" style={textContainerStyle}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">{t("slogan2")} </span></h3>
                                    </div>
                                    <h1 className="title display-one">{t("slogan")} <br /> </h1>
                                    <p className="description"> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                {/* Start Service Area */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    title={t("15")}
                                    description={t("16")}
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 icon-circle-style"
                            textAlign="text-center"
                        />
                    </div>
                </div>

                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    title={t("23")}
                                />
                            </div>
                        </div>
                        <ServiceFour
                            serviceStyle="service__style--1 icon-circle-style with-working-process"
                            textAlign="text-center"
                        />
                    </div>
                </div>

                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    title={t("28")}
                                    subtitle={t("504")}
                                />
                            </div>
                        </div>
                        <ServiceThree
                            serviceStyle="service__style--2"
                            textAlign="text-center"
                        />
                    </div>
                </div>

                <FooterTwo />
                <Copyright />
            </main>
        </>
    );
}

export default BusinessConsulting2;
