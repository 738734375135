import React from 'react';
import { FiPhone, FiMapPin, FiFile } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const AboutFive = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="about-style-5" style={{ marginTop: "40px" }}>
            <div className="full-width-container">  {/* 修改 className 为 full-width-container */}
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row row--0 about-wrapper align-items-center theme-shape">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img src="./images/about/ashely.png" alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="inner">
                                        <p>{t("85")}</p>
                                        <p>{t("86")}</p>
                                        {/* <p>{t("87")}</p> */}
                                        <p>{t("88")}</p>

                                        <div className="download-button mt--20">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutFive;
