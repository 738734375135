import React from 'react';
import { useTranslation } from 'react-i18next';
import './ComparisonItem.css';

const ComparisonItem = ({ id }) => {
  const { t } = useTranslation();
  const item = t(id, { returnObjects: true });

  return (
    <div className="comparison-item">
      <div className="negative">
        <span className="icon">❌</span>
        <span className="text" >{item.negative}</span>
      </div>
      {/* <div className="separator">vs</div> */}
      <div className="positive">
        <span className="icon">✅</span>
        <span className="text" >{item.positive}</span>
      </div>
    </div>
  );
};

export default ComparisonItem;