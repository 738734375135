import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import { useTranslation } from 'react-i18next';
import DemoLine from '../elements/split/chart';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import MapComponent from '../elements/split/map';

const AboutScale = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="About Us - Cyberate Investments, our value, our management scale, our people" />
            <main className="page-wrapper">
                <HeaderOne></HeaderOne>

                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">{t("501")}</h1>
                                    <p className="description">{t("511")}</p>
                                    {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">{t("53")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/服务2.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("166")}</h3>
                                    <p>{t("512")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DemoLine />
                <div className="rwt-brand-area pb--80  pt--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">{t("513")}</h3>
                                    <p>{t("514")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MapComponent />
                <br /><br /><br /><br /> <br /><br /><br /><br />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AboutScale;
