import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. Our team will contact you soon.</p>
    )
}

function ContactForm({ formStyle }) {
    const [result, showresult] = useState(false);
    // const [currentPageUrl, setCurrentPageUrl] = useState(window.location.href);

    const sendEmail = (e) => {
        // console.log(e.target.fullname.value, e.target.email.value, e.target.phone.value, e.target.subject.value, e.target.message.value)
        // var string = "You have received a message from " + e.target.fullname.value + ", " + e.target.email.value + ", " + e.target.phone.value + ", " + e.target.subject.value + ", " + e.target.message.value;
        // TODO: 请参考以下方法对接邮箱服务
        e.preventDefault();

        // add currentPageUrl from props into e.target as a variable
        // console.log("props.currentPageUrl: ", window.location.href);
        e.target.page_info.value = window.location.href;


        emailjs
            .sendForm(
                'service_0qn969g',
                'template_xzim6i9',
                e.target,
                {
                    publicKey: 'PcHzeyHQ7091mGvgC',
                }
            )
            .then((result) => {
                console.log(result.text);
                if (result.text === "OK") {
                    e.target.reset();
                    showresult(true);
                }
            },
                (error) => {
                    console.log(error.text);
                }
            );

    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>


            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>

            <input type="hidden" name="page_info" value={window.location.href} />

            <div className="form-group">
                <button className="btn-default btn-large">Submit Now</button>
            </div>

            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
