import React from 'react';

const BrandList = [
    {
        image: './images/brand/logo-00.png',
        url: 'https://urbantechfinance.com.au/'
    },
    {
        image: './images/brand/logo-07.png',
        url: 'https://thebrokerhubsa.com.au/'
    },

    {
        image: './images/brand/logo-08.png',
        url: 'https://awardmortgage.com.au/'
    },


]

const BrandFour = ({ brandStyle }) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>

            {BrandList.map((data, index) => (
                <li key={index} style={{ margin: '20px' }}>
                    <a href={data.url} target='_blank' style={{ padding: "10px", background: "white" }} rel="noreferrer"> <img src={`${data.image}`} alt="Brand Image" /> </a>
                </li>
            ))}

            <br />
        </ul>
    )
}

export default BrandFour;
