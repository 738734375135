import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import AboutFive from '../about/AboutFive';
import { useTranslation } from 'react-i18next';

const AccordionFour = ({ customStyle }) => {
        const { t, i18n } = useTranslation();

        <style>
                {`
      .custom-card-container .card-body {
        padding: 100px !important;
        border: 1px solid black !important;
      }
    `}
        </style>
        return (

                // <div className="custom-card-container">

                //         <div className="rn-service-area rn-section-gap" >
                //                 <div className="container">

                //                         <AboutFive
                //                                 serviceStyle="service__style--1  icon-circle-style with-working-process"
                //                                 textAlign="text-center"
                //                         />
                //                 </div>
                //         </div> 



                // </div>
                <AboutFive
                        serviceStyle="service__style--1  icon-circle-style with-working-process"
                        textAlign="text-center"
                />




        )
}
export default AccordionFour;
