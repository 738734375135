import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import ContactOne from "./ContactOne";
import HeaderTopBar from '../../common/header/HeaderTopBar';
import HeaderOne from "../../common/header/HeaderOne";
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactForm from './ContactForm';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from 'react-i18next';



const Contact = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {/* <SEO title="Contact || Cyberate Investments Pty Ltd" /> */}
            {/* <Layout> */}
            {/* <HeaderTopBar></HeaderTopBar> */}
            <HeaderOne></HeaderOne>
            <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div className="inner text-start">
                                <h1 className="title theme-gradient display-two">{t("53")} </h1>
                                <p className="description">{t("517")}</p>
                                {/* <div className="button-group"> */}
                                {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link> */}
                                {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">联系我们<i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 order-1 order-lg-2">
                            <div className="thumbnail">
                                <img src="./images/bg/联系我们2.png" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-content">
                {/* Start Contact Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t("142")}
                                    title={t("141")}
                                    description=""
                                />
                            </div>
                        </div>

                        <ContactOne />



                    </div>
                </div>
                {/* End Contact Area  */}
            </div>


            {/* </Layout> */}
            <FooterTwo />
            <Copyright />
        </>
    )
}
export default Contact;